import Action from '../action'

const initState = {
    firstname: '',
    lastname: '',
    prefix: '',
    taxid:'',
    tel: '',
    email: '',
    typemeet: '',
    typesubject: '',
    title: '',
    description: '',
    date: '',
    round: '',
    file: null,
    image: '',
    LineID: '',
    Company: '',
    meettype: '',
    dateform: '',
    org: '',
    worktype: '',
    worktype2: '',
    worktype3: '',
    worktype4: '',
    subworktype: '',
    typedocapp: '',
    typerequest: '',
    Companystaff: '',
    filename: '',
    Companytel: '',
    Companyemail: '',
    workdetail:{},
    counter:''

}
const FormReducer = (state = initState, action) => {
    switch (action.type) {
        case Action.setfrom:
            const newState = { ...state }
            newState[action.name] = action.value
            return newState
        case Action.clear:
            return initState
        default:
            return state
    }
}
export default FormReducer
